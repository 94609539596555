import * as React from 'react'
import { withPrismicUnpublishedPreview, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import linkResolver from '../lib/link-resolver'

import PageTemplate from '../templates/prismic-page'
import ArticleTemplate from '../templates/prismic-article'
import Layout from '../components/layout'
import { StyledSmallWrapper } from '../components/styles'

const NotFoundPage = () => {
  return (
    <Layout>
      <StyledSmallWrapper>
        <h1 className="font-gradient-blue">404 Pagina niet gevonden</h1>
        <p>De pagina die je probeert de bezoeken bestaat helaas niet.</p>
      </StyledSmallWrapper>
    </Layout>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PageTemplate,
      article: ArticleTemplate,
    }),
  },
])
