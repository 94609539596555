import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import linkResolver from '../lib/link-resolver'
import Seo from '../components/seo'
import Layout from '../components/layout'
import slices from '../components/prismic-slices'
import RelatedArticles from '../components/related-articles'
import ArticleHeader from '../components/article-header'
import { StyledArticleWrapper } from '../components/styles'
import WhitePaperButton from '../components/whitepaper-button'

const ArticleTemplate = ({ data }) => {
  const page = data.prismicArticle
  const image = getImage(page.data.featured_image)

  const whitePaper = {
    url: page.data.whitepaper_download_url,
    button: page.data?.whitepaper_download_button_text ?? 'Download whitepaper',
  }

  return (
    <Layout>
      <Seo
        title={page.data.title.text}
        description={page.data.excerpt}
        image={page.data.featured_image.thumbnails.OpenGraph.url}
        article={true}
      />
      <StyledArticleWrapper>
        <article id="article">
          <ArticleHeader categories={page.data.categories} tags={page.tags}>
            <h1 className="font-gradient-blue header-breakout">{page.data.title.text}</h1>
          </ArticleHeader>

          {image && (
            <figure>
              <GatsbyImage image={image} alt={page.data.featured_image.alt || page.data.title.text} />
            </figure>
          )}

          {page.data.body?.map((slice) => {
            const Slice = slices[slice.slice_type]
            return Slice ? <Slice key={slice.id} {...slice} whitePaper={whitePaper} /> : null
          })}
        </article>

        {page.data.whitepaper_download_url ? (
          <aside>
            <h2 className="font-gradient-grey">Whitepaper</h2>
            <WhitePaperButton whitePaper={whitePaper}>{whitePaper.button}</WhitePaperButton>
          </aside>
        ) : null}

        {data.allPrismicArticle?.edges.length ? (
          <section>
            <h2 className="font-gradient-yellow header-size-large">Gerelateerde artikelen</h2>
            <RelatedArticles articleList={data.allPrismicArticle?.edges} />
          </section>
        ) : null}
      </StyledArticleWrapper>
    </Layout>
  )
}

export default withPrismicPreview(ArticleTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
])

export const query = graphql`
  query ArticleQuery($uid: String!, $categories: [String], $tags: [String]) {
    prismicArticle(uid: { eq: $uid }) {
      _previewable
      data {
        title {
          text
        }
        excerpt
        whitepaper_download_url
        whitepaper_download_button_text
        categories {
          category {
            document {
              ... on PrismicCat {
                id
                data {
                  titel
                }
              }
            }
          }
        }
        featured_image {
          alt
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          thumbnails {
            OpenGraph {
              url
            }
          }
        }
        publication_date(formatString: "DD-MM-YYYY")
        body {
          ... on PrismicArticleDataBodyContent {
            ...contentArticleFragment
          }
          ... on PrismicArticleDataBodyContentSectie {
            ...contentSectionArticleFragment
          }
          ... on PrismicArticleDataBodyEmbed {
            ...embedArticleFragment
          }
        }
      }
      tags
    }

    allPrismicArticle(
      sort: { fields: last_publication_date, order: DESC }
      filter: {
        data: { categories: { elemMatch: { category: { uid: { in: $categories } } } } }
        uid: { ne: $uid }
        tags: { in: $tags }
      }
      limit: 3
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            featured_image {
              thumbnails {
                Thumbnail {
                  alt
                  gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
        }
      }
    }
  }
`
